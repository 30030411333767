import React, { useState, createContext } from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { amber, green } from "@material-ui/core/colors"
import { Snackbar, IconButton, SnackbarContent } from "@material-ui/core"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import ErrorIcon from "@material-ui/icons/Error"
import InfoIcon from "@material-ui/icons/Info"
import CloseIcon from "@material-ui/icons/Close"
import WarningIcon from "@material-ui/icons/Warning"

// This could use some cleanup. Usage:
// import { SnackbarContext } from "components/common/snackbar-context"
//
// const openSnackbar = useContext(SnackbarContext)
// openSnackbar(message, variant)
//
// variant options: success (default), warning, error, info

export const SnackbarContext = createContext({
  setOpen: () => {},
})

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const styles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}))

function MySnackbarContentWrapper({
  className,
  message,
  onClose,
  variant,
  ...other
}) {
  const classes = styles()
  const Icon = variantIcon[variant]

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  )
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["error", "info", "success", "warning"]).isRequired,
}

export const SnackbarProvider = ({ children }) => {
  const [state, setState] = useState({
    message: "Default",
    open: false,
    variant: "success",
  })

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setState({
      ...state,
      open: false,
    })
  }

  const openSnack = (message, variant = "success") => {
    setState({
      open: true,
      message,
      variant,
    })
  }

  return (
    <SnackbarContext.Provider value={openSnack}>
      {children}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={state.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper
          onClose={handleClose}
          variant={state.variant}
          message={state.message}
        />
      </Snackbar>
    </SnackbarContext.Provider>
  )
}

export const SnackbarConsumer = SnackbarContext.Consumer
