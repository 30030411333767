import CssBaseline from "@material-ui/core/CssBaseline"
import { ThemeProvider } from "@material-ui/styles"
import PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet"
import { ConfirmationModalProvider } from "../../src/components/common/confirmation-modal-context"
import { SnackbarProvider } from "../../src/components/common/snackbar-context"
import WithFirebase from "../../src/components/common/with-firebase"
import theme from "../../src/theme"
import {InfoModalProvider} from "../../src/components/common/info-modal-context";

export default function TopLayout(props) {
  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <WithFirebase>
          <InfoModalProvider>
            <ConfirmationModalProvider>
              <SnackbarProvider>{props.children}</SnackbarProvider>
            </ConfirmationModalProvider>
          </InfoModalProvider>
        </WithFirebase>
      </ThemeProvider>
    </React.Fragment>
  )
}

TopLayout.propTypes = {
  children: PropTypes.node,
}
