import { Box, Button } from "@material-ui/core"
import React, { createContext, useState } from "react"
import StyledModal from "./styled-modal"

const UNOPEN_STATE = {
  message: null,
  open: false,
  onConfirm: null,
}

export const InfoModalContext = createContext({})

export const InfoModalProvider = ({ children }) => {
  const [state, setState] = useState(UNOPEN_STATE)

  const closeModal = () => {
    setState(UNOPEN_STATE)
  }

  const openModal = (title, message, onConfirm) => {
    setState({
      open: true,
      title,
      message,
      onConfirm,
    })
  }

  return (
    <InfoModalContext.Provider value={openModal}>
      {children}

      <StyledModal isOpen={state.open} close={closeModal} title={state.title}>
        <Box px={4} py={2} width={500}>
          <Box>{state.message}</Box>

          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Box mr={1}>
              <Button variant="contained" onClick={closeModal}>
                Close
              </Button>
            </Box>
          </Box>
        </Box>
      </StyledModal>
    </InfoModalContext.Provider>
  )
}
