/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles.css"

export const onClientEntry = () => {
  const { self, top } = window

  // feat (RAC-86): Prevent the app to be loaded within iframe.
  if (self === top) {
    document.documentElement.style.visibility = "visible"
  } else {
    top.location = self.location
  }
}
