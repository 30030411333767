import { createTheme, responsiveFontSizes } from "@material-ui/core/styles"

export const primaryNavyBlue = "#1A2958"
export const primaryMidBlue = "#0079B0"
export const primaryTeal = "#46C1BE"
export const primaryGreen = "#118668"
export const primaryOrange = "#E66A4A"
export const primarySalmon = "#F3756C"
export const primaryIvory = "#FDFAF5"

export const textDarkGray = "#5F647C"

export const tintsLightTeal = "#ADDCCF"
export const tintsNavyBlue = "#2F4079"
export const tintsLightBlue = "#579EC6"

// View options here: https://material-ui.com/customization/default-theme/#default-theme
const theme = createTheme({
  palette: {
    primary: {
      dark: primaryNavyBlue,
      main: tintsNavyBlue,
      light: primaryMidBlue
    },
    secondary: {
      main: primaryTeal
    },
    MuiLink: {
      root: {
        color: tintsNavyBlue
      }
    },
    background: {
      default: "#fff"
    }
  },
  typography: {
    fontFamily: '"Greycliff CF", sans-serif',
    h1: {
      fontSize: "3.8rem",
      fontWeight: 800
    },
    h2: {
      fontSize: "2.6rem",
      fontWeight: 700,
      color: primaryTeal
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: 600
    },
    h5: {
      fontSize: "1.45rem"
    },
    body1: {
      fontSize: 20,
      color: textDarkGray
    }
  },

  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
        boxShadow: "none",
        fontWeight: 700,
        borderRadius: 30
      },
      contained: {
        boxShadow: "none"
      },
      outlined: {}
    },
    // https://github.com/mui-org/material-ui/issues/7466
    MuiGrid: {
      container: {
        width: "100% !important",
        margin: "0 !important"
      }
    }
  },
  scrollbar: {
    "&::-webkit-scrollbar": {
      cursor: "pointer",
      width: 4
    },
    "&::-webkit-scrollbar:hover": {
      width: 8
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: 3
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#aaa",
      borderRadius: 3
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#999",
      width: 8
    }
  }
})

export default responsiveFontSizes(theme)
