const config = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
}

export const modules = {
  ASSIGN_PATIENT: { active: false },
}

let firebaseInstance;
let analytics;

export const getFirebase = firebase => {
  if (firebaseInstance) {
    return firebaseInstance;
  }

  firebase.initializeApp(config);
  firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
  firebaseInstance = firebase;
  analytics = firebaseInstance.analytics(); // v7 api

  return firebase;
};

export const getFirestore = () => {
  const firebase = getFirebase();
  return firebase.firestore();
};

export const getFunctions = () => {
  const firebase = getFirebase();
  const functions = firebase.app().functions("asia-east2");
  // if (process.env.NODE_ENV === "development") {
    // console.log("DEV ----> Using functions emulator")
    // const emuHost =
    //   process.env.GATSBY_FIREBASE_FUNCTION_EMULATOR_HOST || "http://localhost:5001"
    // functions.useFunctionsEmulator(emuHost)
  // }
  return functions;
};
export const logAnalytics = (label, details) => {
  // v7 firebase api
  analytics.logEvent(label, details);
};
