import { Box, Button } from "@material-ui/core"
import React, { createContext, useState } from "react"
import SpinnerButton from "./spinner-button"
import StyledModal from "./styled-modal"

const UNOPEN_STATE = {
  message: null,
  open: false,
  onConfirm: null,
}

export const ConfirmationModalContext = createContext({})

export const ConfirmationModalProvider = ({ children }) => {
  const [state, setState] = useState(UNOPEN_STATE)

  const [loading, setLoading] = useState(false)

  const confirm = async () => {
    setLoading(true)
    await state.onConfirm()
    setLoading(false)
    closeModal()
  }

  const closeModal = () => {
    setState(UNOPEN_STATE)
  }

  const openModal = (message, onConfirm) => {
    setState({
      open: true,
      message,
      onConfirm,
    })
  }

  return (
    <ConfirmationModalContext.Provider value={openModal}>
      {children}

      <StyledModal isOpen={state.open} close={closeModal} title="Are you sure?">
        <Box px={4} py={2} width={500}>
          <Box>{state.message}</Box>

          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Box mr={1}>
              <Button variant="contained" onClick={closeModal}>
                No
              </Button>
            </Box>
            <SpinnerButton
              loading={loading}
              variant="contained"
              color="primary"
              onClick={confirm}
            >
              Yes
            </SpinnerButton>
          </Box>
        </Box>
      </StyledModal>
    </ConfirmationModalContext.Provider>
  )
}
