import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Typography, Box } from "@material-ui/core"
import clsx from "clsx"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottom: "1px solid #465895",
  },
  inverted: {
    backgroundColor: theme.palette.grey[200],
    borderBottom: "1px solid #E0E0E0",
    "& $heading": {
      color: theme.palette.primary.main,
    },
  },
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  heading: {
    color: "white",
    fontSize: 22,
  },
}))

export default function BannerTop({ title, contentRight, inverted }) {
  const classes = useStyles()

  return (
    <Box className={clsx(classes.root, { [classes.inverted]: inverted })}>
      <Box
        px={{ xs: 3, md: 4 }}
        py={2}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4" className={classes.heading}>
          {title}
        </Typography>
        {contentRight}
      </Box>
    </Box>
  )
}
