import React, { useEffect, useMemo, useState } from "react"
import { getFirebase } from "../../lib/firebase"
import { FirebaseProvider } from "./firebase-context"
import useLocalStorage from "../../lib/use-localstorage"

function logDev(msg, obj = null) {
  process.env.NODE_ENV === "development" &&
    console.log(`%cDEV----> ${msg}`, `color:blue`, obj)
}

export default function WithFirebase({ children }) {
  const [currentLoggedInPractice, setCurrentLoggedInPractice] = useLocalStorage(
    "RACGP_CURRENT_LOGGED_IN_PRACTICE"
  )
  const [firebaseInitialised, setFirebaseInitialised] = useState(false)
  const [auth, setAuth] = useState(null)
  const [userData, setUserData] = useState(null)
  const [currentPractice, setCurrentPractice] = useState(null)

  const parseLoggedInPractice = useMemo(() => {
    let parseCurrentLoggedInPractice
    try {
      parseCurrentLoggedInPractice = currentLoggedInPractice
        ? JSON.parse(currentLoggedInPractice)
        : null
    } catch (e) {}

    return parseCurrentLoggedInPractice
  }, [currentLoggedInPractice])

  useEffect(() => {
    const lazyApp = import("firebase/app")
    const lazyAuth = import("firebase/auth")
    const lazyFunctions = import("firebase/functions")
    const lazyAnalytics = import("firebase/analytics")
    const lazyFirestore = import("firebase/firestore")
    const lazyPerformance = import("firebase/performance")
    let removeOnAuthStateChanged = () => {}

    // Firebase needs to be init'd inside a component
    // Apparently the Firebase init code references the `window` object.
    // But Gatsby build() runs in a Node environment - no `window` exists.
    // https://kyleshevlin.com/firebase-and-gatsby-together-at-last

    Promise.all([
      lazyApp,
      lazyAuth,
      lazyFunctions,
      lazyAnalytics,
      lazyFirestore,
      lazyPerformance,
    ]).then(([firebase]) => {
      const fb = getFirebase(firebase)
      const auth = fb.auth()

      logDev("Setting up firebase")

      fb.analytics()
      fb.performance()
      const firestore = fb.firestore()
      if (window !== undefined && process.env.NODE_ENV === "development") {
        window.firestore = firestore
      }
      const analytics = firebase.analytics()
      let unsubUserData = () => {}
      let unsubCurrentPractice = () => {}

      removeOnAuthStateChanged = auth.onAuthStateChanged((user) => {
        logDev("Firebase onAuthStateChange() called. User: ", user)

        if (user) {
          logDev("Subcribing to user data")
          unsubUserData = firestore
            .collection("users")
            .doc(user.uid)
            .onSnapshot((doc) => {
              let data = { id: doc.id, ...doc.data() }
              logDev("User data changed", data)
              setUserData(data)

              analytics.setUserId(user.uid)
              analytics.setUserProperties({ user_id: user.uid })

              const currentPracticeId =
                parseLoggedInPractice?.id || data.currentPracticeId || null

              if (currentPracticeId) {
                unsubCurrentPractice()
                unsubCurrentPractice = firestore
                  .collection("practices")
                  .doc(currentPracticeId)
                  .onSnapshot((practiceDoc) => {
                    let prac = {
                      id: practiceDoc.id,
                      ...practiceDoc.data(),
                    }
                    logDev("Current practice changed", prac)
                    setCurrentPractice(prac)
                  })
              } else {
                setCurrentPractice(null)
                unsubCurrentPractice()
              }
            })
        } else {
          setCurrentLoggedInPractice("")
          logDev("User logged out")

          setUserData(null)
          unsubUserData()

          setCurrentPractice(null)
          unsubCurrentPractice()
        }

        setAuth(user)
        setFirebaseInitialised(true)
      })
      window.hh_analytics = analytics
    })

    return () => {
      removeOnAuthStateChanged()
    }
  }, [])

  return (
    <FirebaseProvider
      value={{
        user: auth,
        firebaseInitialised,
        userData,
        currentPractice,
        currentLoggedInPractice: parseLoggedInPractice,
      }}
    >
      {children}
    </FirebaseProvider>
  )
}
