import { useEffect, useState } from "react"

function useLocalStorage(key, initialValue) {
  const storedValue = typeof window !== 'undefined' ? localStorage.getItem(key) : initialValue

  const [value, setValue] = useState(storedValue)

  useEffect(() => {
    const handleStorageChange = e => {
      if (e.key === key) {
        setValue(e.newValue)
      }
    }

    window.addEventListener("storage", handleStorageChange)

    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [key])

  const updateValue = newValue => {
    setValue(newValue)
    localStorage.setItem(key, newValue)
  }

  return [value, updateValue]
}

export default useLocalStorage
