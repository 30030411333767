import { Box, Modal, Typography, IconButton } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import React from "react"
import BannerTop from "../app/banner-top"
import CloseIcon from "@material-ui/icons/Close"

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconButton: {
    padding: 0,
  },
  closeIcon: {
    color: "#fff",
    fontSize: 20,
  },
  subTitle: {
    backgroundColor: theme.palette.grey[200],
  },
  paper: {
    backgroundColor: "#FAFAFA",
    borderRadius: 6,
    boxShadow: theme.shadows[5],
  },
}))

const StyledModal = ({ isOpen, close, title, subTitle, children }) => {
  const classes = useStyles()

  return (
    <Modal className={classes.modal} open={isOpen} onClose={close}>
      <Box className={classes.paper}>
        <BannerTop
          title={title}
          contentRight={
            <IconButton onClick={close} className={classes.iconButton}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          }
        />
        {subTitle && (
          <Box px={4} py={2.5} className={classes.subTitle}>
            <Typography variant="h5" color="primary">
              {subTitle}
            </Typography>
          </Box>
        )}
        {children}
      </Box>
    </Modal>
  )
}

StyledModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

export default StyledModal
