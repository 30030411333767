exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-apps-js": () => import("./../../../src/pages/apps.js" /* webpackChunkName: "component---src-pages-apps-js" */),
  "component---src-pages-clinician-dashboard-js": () => import("./../../../src/pages/clinician-dashboard.js" /* webpackChunkName: "component---src-pages-clinician-dashboard-js" */),
  "component---src-pages-clinicians-getting-started-js": () => import("./../../../src/pages/clinicians-getting-started.js" /* webpackChunkName: "component---src-pages-clinicians-getting-started-js" */),
  "component---src-pages-condition-specific-advice-js": () => import("./../../../src/pages/condition-specific-advice.js" /* webpackChunkName: "component---src-pages-condition-specific-advice-js" */),
  "component---src-pages-conversations-js": () => import("./../../../src/pages/conversations.js" /* webpackChunkName: "component---src-pages-conversations-js" */),
  "component---src-pages-faq-patient-js": () => import("./../../../src/pages/faq-patient.js" /* webpackChunkName: "component---src-pages-faq-patient-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-patient-pathway-behaviour-change-js": () => import("./../../../src/pages/patient-pathway/behaviour-change.js" /* webpackChunkName: "component---src-pages-patient-pathway-behaviour-change-js" */),
  "component---src-pages-patient-pathway-getting-started-js": () => import("./../../../src/pages/patient-pathway/getting-started.js" /* webpackChunkName: "component---src-pages-patient-pathway-getting-started-js" */),
  "component---src-pages-patient-pathway-index-js": () => import("./../../../src/pages/patient-pathway/index.js" /* webpackChunkName: "component---src-pages-patient-pathway-index-js" */),
  "component---src-pages-patient-pathway-question-js": () => import("./../../../src/pages/patient-pathway/question.js" /* webpackChunkName: "component---src-pages-patient-pathway-question-js" */),
  "component---src-pages-phone-app-js": () => import("./../../../src/pages/phone-app.js" /* webpackChunkName: "component---src-pages-phone-app-js" */),
  "component---src-pages-qr-invitation-js": () => import("./../../../src/pages/qr-invitation.js" /* webpackChunkName: "component---src-pages-qr-invitation-js" */),
  "component---src-pages-resource-hub-js": () => import("./../../../src/pages/resource-hub.js" /* webpackChunkName: "component---src-pages-resource-hub-js" */),
  "component---src-pages-setting-up-your-practice-js": () => import("./../../../src/pages/setting-up-your-practice.js" /* webpackChunkName: "component---src-pages-setting-up-your-practice-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-conversation-exercise-js": () => import("./../../../src/templates/conversation-exercise.js" /* webpackChunkName: "component---src-templates-conversation-exercise-js" */),
  "component---src-templates-conversation-nutrition-js": () => import("./../../../src/templates/conversation-nutrition.js" /* webpackChunkName: "component---src-templates-conversation-nutrition-js" */)
}

