import React from "react"

const FirebaseContext = React.createContext({
  firebaseInitialised: false,
  user: null,
  userData: null,
})

export const FirebaseProvider = FirebaseContext.Provider
export const FirebaseConsumer = FirebaseContext.Consumer
export default FirebaseContext
