import React from "react"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import { withStyles } from "@material-ui/styles"
const styles = {
  root: {
    marginLeft: 8,
  },
}
const SpinnerAdornment = withStyles(styles)(props => (
  <CircularProgress className={props.classes.root} size={20} />
))
const SpinnerButton = props => {
  const { children, loading, ...rest } = props
  return (
    <Button {...rest} disabled={loading}>
      {children}
      {loading && <SpinnerAdornment />}
    </Button>
  )
}

export default SpinnerButton
